import React, { useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { format, subMinutes } from 'date-fns';
import { Link } from 'react-router-dom';

import CommonButtonDefault from '../common/buttons/default';
import StyledError from '../common/form/styled-error';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import { strings } from '../../localization/strings';
import { Location } from '../../entities/location';
import AlertPrimary from '../common/alerts/alert-primary';
import { Option } from '../../entities/option';
import CommonAnimatedLoader from '../common/animated/loader';
import { Order } from '../../entities/order';

interface ComponentProps {
  locations: Location[];
  onCompleteCreateOrderForm: (data: Order) => void;
}

function CreateOrderFormComponent({ locations, onCompleteCreateOrderForm }: ComponentProps) {
  const [isLoading] = useState<boolean>(false);

  const genderOptions: Option[] = [
    {
      value: 1,
      label: strings().gender.male,
    },
    {
      value: 2,
      label: strings().gender.female,
    },
  ];

  const productOptions: Option[] = [
    {
      value: 17,
      label: strings().product.foodId,
    },
    {
      value: 19,
      label: strings().product.allergyChipPro,
    },
    {
      value: 13,
      label: strings().product.allergyChip,
    },
  ];

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<Order>();
  const onSubmit: SubmitHandler<Order> = async (data) => {
    try {
      const formData = data;
      const createdDate = format(new Date(data.birthDate), 'yyyy-MM-dd');

      if (createdDate) {
        formData.birthDate = createdDate;
      }

      onCompleteCreateOrderForm(formData);
    } catch (e) {
      if (e instanceof Error) {
        setError('common', { message: e.message });
      }
    }
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      {errors.common && <AlertPrimary title={errors?.common?.message} type="danger" marginBottom="mb-4" />}
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-5">
          <div>
            <StyledLabel htmlFor="locationId">{strings().orderCreation.clinic}</StyledLabel>
            {locations && (
              <div>
                <Controller
                  control={control}
                  name="locationId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="react-select"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: errors.locationId ? '#f56565' : 'rgba(209, 213, 219)',
                        }),
                      }}
                      className="block w-full rounded-md !border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      options={locations}
                      value={locations.find((option) => option.id === field.value)}
                      placeholder={strings().bloodRegistration.pickerPlaceholder}
                      getOptionValue={(option: Location) => `${option.id}`}
                      getOptionLabel={(option: Location) => `${option.partnerName}, ${option.title}`}
                      onChange={(e) => {
                        field.onChange(e?.id);
                      }}
                    />
                  )}
                />
                {errors.locationId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
              </div>
            )}
          </div>

          <div>
            <StyledLabel htmlFor="productId">{strings().product.label}</StyledLabel>
            <Controller
              control={control}
              name="productId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  placeholder={strings().form.select.pickerPlaceholder}
                  classNamePrefix="react-select"
                  options={productOptions}
                  value={productOptions.find((option) => option.value === field.value)}
                  className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    errors.productId && `!border-red-500`
                  } `}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: errors.productId ? '#f56565' : 'rgba(209, 213, 219)',
                    }),
                  }}
                  onChange={(e) => {
                    field.onChange(e?.value);
                  }}
                />
              )}
            />
            {errors.productId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>

          <div>
            <StyledLabel htmlFor="firstName">{strings().orderCreation.patientName}</StyledLabel>
            <StyledInput
              fullWidth
              type="text"
              className={` ${errors.firstName && `!border-red-500`} `}
              {...register('firstName', { required: true })}
            />
            {errors.firstName && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>
          <div>
            <StyledLabel htmlFor="lastName">{strings().orderCreation.patientLastName}</StyledLabel>
            <StyledInput
              fullWidth
              type="text"
              className={` ${errors.lastName && `!border-red-500`} `}
              {...register('lastName', { required: false })}
            />
            {errors.lastName && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>
          <div>
            <StyledLabel htmlFor="birthDate">{strings().orderCreation.birthDate}</StyledLabel>
            <div>
              <Controller
                control={control}
                name="birthDate"
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    // placeholderText={strings().form.placeholderBirthDate}
                    selected={field.value ? new Date(field.value) : undefined}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.birthDate && `!border-red-500`
                    } `}
                  />
                )}
              />
              {errors.birthDate && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>
          </div>
          <div>
            <StyledLabel htmlFor="age">{strings().orderCreation.age}</StyledLabel>
            <StyledInput
              fullWidth
              type="number"
              className={` ${errors.age && `!border-red-500`} `}
              {...register('age', { required: false })}
            />
            {errors.age && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>

          <div>
            <StyledLabel htmlFor="genderId">{strings().gender.label}</StyledLabel>
            <Controller
              control={control}
              name="genderId"
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  placeholder={strings().form.select.pickerPlaceholder}
                  classNamePrefix="react-select"
                  options={genderOptions}
                  value={genderOptions.find((option) => option.value === field.value)}
                  className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                    errors.genderId && `!border-red-500`
                  } `}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: errors.genderId ? '#f56565' : 'rgba(209, 213, 219)',
                    }),
                  }}
                  onChange={(e) => {
                    field.onChange(e?.value);
                  }}
                />
              )}
            />
            {errors.genderId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
          </div>
        </div>
        <div className="flex space-x-4 mt-6">
          <CommonButtonDefault type="submit" primary>
            {strings().button.confirm}
          </CommonButtonDefault>
          <Link className="ml-auto" to="/">
            <CommonButtonDefault>{strings().button.back}</CommonButtonDefault>
          </Link>
        </div>
      </form>
    </div>
  );
}

CreateOrderFormComponent.defaultProps = {};

export default CreateOrderFormComponent;
