export const strings = {
  error: {
    fieldIsRequired: 'Laukelis privalomas',
  },
  alert: {
    success: {
      title: 'Sėkmingai išsaugota',
    },
    danger: {
      title: 'Nepavyko rasti užsakymo',
    },
    warning: {
      title: 'Duomenys dar nebuvo išsaugoti.',
      text: 'Patvirtinkite įvestus duomenis.',
    },
  },
  button: {
    login: 'Login',
    close: 'Close',
    edit: 'Keisti',
    delete: 'Delete',
    next: 'Tęsti',
    confirm: 'Patvirtinti',
    back: 'Grįžti atgal',
    createOrder: 'Naujas užsakymas',
    searchOrder: 'Ieškoti užsakymo',
  },
  navigation: {
    bloodSampleRegistration: 'Kraujo mėginio registracija',
    phone: '+370 640 65680',
    haveQuestions: 'Kyla klausimų?',
  },
  breadcrumbs: {
    searchPatient: 'Paciento paieška',
    registerSample: 'Ėminio  registracija',
    preview: 'Peržiūra',
  },
  form: {
    email: 'Email address',
    password: 'Password',
    orderId: 'Užsakymo numeris',
    birthDate: 'Gimimo data',
    search: 'Ieškoti',
    save: 'Išsaugoti',
    comments: 'Pastabos',
    placeholderBirthDate: 'Įrašykite gimimo datą',
    birthDateFormat: 'Datą rašykite atskirdami brūkšneliais, pvz.: 1999-12-08',
    select: {
      pickerPlaceholder: 'Pasirinkite',
    },
  },
  header: {
    orderId: 'Užsakymo numeris',
  },
  bloodSampleRegistration: {
    clinic: 'Ėminį paėmusi įstaiga',
    employee: 'Ėminį paėmęs darbuotojas',
    sampleDate: 'Ėminio paėmimo data',
    sampleTime: 'Ėminio paėmimo laikas',
    amountOfSamples: 'Ėminių skaičius',
    type: 'Ėminio tipas',
    anatomicalArea: 'Anatominė vieta',
    comments: 'Pastabos',
  },
  other: 'kita',

  anatomicalArea: {
    label: 'Anatominė vieta',
    anatomicalAreaOptions: {
      fingerPhalanx: 'Piršto falanga',
      internalFlexionOfElbow: 'Vidinis alkūnės linkis',
    },
  },

  sampleType: {
    label: 'Ėminio tipas',
    sampleTypeOptions: {
      capillaryBlood: 'Kapiliarinis kraujas',
      venousBlood: 'Veninis kraujas',
      nasalSwab: 'Nosies landų tepinėlis',
      bloodSerum: 'Blood serum',
      dryBloodSample: 'Sauso kraujo ėminys',
    },
  },
  bloodRegistration: {
    pickerPlaceholder: 'Pasirinkite kliniką ir padalinį',
  },
  gender: {
    label: 'Lytis',
    male: 'Vyras',
    female: 'Moteris',
  },
  product: {
    label: 'Produktas',
    foodId: 'FoodId',
    allergyChipPro: 'Allergy Chip Doctor Pro',
    allergyChip: 'Allergy Chip',
  },
  orderCreation: {
    clinic: 'Ėminį paėmusi įstaiga',
    patientName: 'Paciento vardas',
    patientLastName: 'Paciento pavardė',
    birthDate: 'Gimimo data',
    age: 'Amžius',
  },
  appUrl: 'https://partners.allergomedica.lt',
};
